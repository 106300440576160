import { useDispatch, useSelector } from 'react-redux'
import {
  openWebPhone,
  closeWebPhone,
  toggleWebPhone
} from 'src/store/webPhone/webPhone-slice'
import { AppDispatch, RootState } from 'src/store'

interface UseWebPhoneHook {
  open: (phoneNumber?: string) => void
  close: () => void
  toggle: (phoneNumber?: string) => void
  isOpen: boolean
  phoneNumber: string
}

const useWebPhone = (): UseWebPhoneHook => {
  const dispatch = useDispatch<AppDispatch>()
  const { isOpen, phoneNumber } = useSelector(
    (state: RootState) => state.webPhone
  )

  const open = (phoneNumber?: string) => {
    dispatch(openWebPhone({ phoneNumber }))
  }

  const close = () => {
    dispatch(closeWebPhone())
  }

  const toggle = (phoneNumber?: string) => {
    dispatch(toggleWebPhone({ phoneNumber }))
  }

  return { open, close, toggle, isOpen, phoneNumber }
}

export default useWebPhone
