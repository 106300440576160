import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, ModalBody, ModalHeader } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import {
  ExtensionType,
  useAddExtensionTypeMutation,
  useGetExtensionTypesQuery,
  useUpdateExtensionTypeMutation
} from 'src/services/extension-settings'
import { LIST_LIMIT } from 'src/client'
import { DangerAlert } from '../ErrorPage'
import List from 'src/Components/Common/EntitiesView/List'
import { useModalDisclosureContext } from 'src/ModalDisclosureProvider'
import { toast } from 'react-toastify'
import { EditExtensionTypeForm } from './EditExtensionTypeForm'

export const ExtensionTypes = () => {
  const { t } = useTranslation()
  const modalDisclosure = useModalDisclosureContext()

  const { data: extensionTypes, error } = useGetExtensionTypesQuery({
    limit: LIST_LIMIT,
    offset: 0
  })
  const [addExtensionType] = useAddExtensionTypeMutation()
  const [updateExtensionType] = useUpdateExtensionTypeMutation()

  const title = t('Extension types')
  document.title = title

  const handleAddExtensionTypeClick = async () => {
    try {
      const values = await modalDisclosure.open<ExtensionType>((props) => (
        <>
          <ModalHeader toggle={props.reject}>{t('Create')}</ModalHeader>
          <ModalBody>
            <EditExtensionTypeForm {...props} />
          </ModalBody>
        </>
      ))
      try {
        await addExtensionType(values).unwrap()
        toast.success(t('Successfully added'), {
          autoClose: 2000,
          closeOnClick: false
        })
      } catch (error) {
        toast.error(t('Error adding'), {
          autoClose: 2000,
          closeOnClick: false
        })
      }
    } catch (error) {
      // noop
    }
  }

  const handleEditExtensionTypeClick = async (extensionType: ExtensionType) => {
    try {
      const values = await modalDisclosure.open<ExtensionType>((props) => (
        <>
          <ModalHeader toggle={props.reject}>{t('Edit')}</ModalHeader>
          <ModalBody>
            <EditExtensionTypeForm extensionType={extensionType} {...props} />
          </ModalBody>
        </>
      ))
      try {
        await updateExtensionType({ ...values, id: extensionType.id }).unwrap()
        toast.success(t('Successfully updated'), {
          autoClose: 2000,
          closeOnClick: false
        })
      } catch (error) {
        toast.error(t('Error updating'), {
          autoClose: 2000,
          closeOnClick: false
        })
      }
    } catch (error) {
      // noop
    }
  }

  const transformedExtensionTypes = extensionTypes?.map((item) => ({
    ...item,
    is_new_client: item?.other_conditions?.is_new_client,
    agencies_short_names: item?.other_conditions?.agencies_short_names,
    last_phones_digit: item?.other_conditions?.last_phones_digit
  }))

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={t('Admin')} />
      <Card>
        <CardBody>
          <div>
            <Button onClick={handleAddExtensionTypeClick}>{t('Add')}</Button>
            {error && <DangerAlert error={error} />}
            {transformedExtensionTypes?.length ? (
              <List
                list={transformedExtensionTypes}
                fieldsDescription={{
                  id: {
                    label: ' ',
                    isButton: true,
                    mappingValue: () => <i className='ri-eye-line fs-15' />,
                    onClick: handleEditExtensionTypeClick
                  },
                  name: {},
                  enable: {
                    label: 'enabled',
                    mappingValue: (value: boolean) =>
                      value ? t('On') : t('Off')
                  },
                  min_amount: {
                    label: 'minimum loan amount',
                    isAmount: true
                  },
                  max_amount: {
                    label: 'maximum loan amount',
                    isAmount: true
                  },
                  min_dpd: {},
                  max_dpd: {},
                  min_loans: {
                    label: `${t('loan_short_name')}  ${t('min')}`
                  },
                  max_loans: {
                    label: `${t('loan_short_name')}  ${t('max')}`
                  },
                  is_new_client: {
                    label: `${t('client type')}`,
                    mappingValue: (value: boolean) =>
                      value === true
                        ? t('Primary')
                        : value === false
                        ? t('Repeated')
                        : '-'
                  },
                  agencies_short_names: {
                    label: `${t('Agency')}`,
                    mappingValue: (value: string[] | undefined) =>
                      Array.isArray(value) ? value.join(',') : '-'
                  },
                  last_phones_digit: {
                    label: `${t('Last digit of the number')}`,
                    mappingValue: (value: string[] | undefined) =>
                      Array.isArray(value) ? value.join(',') : '-'
                  }
                }}
              />
            ) : (
              <div style={{ marginBottom: '10px' }}>
                <dl className='row mb-0'>
                  <small>{t('No data')}</small>
                </dl>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
