import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface WebPhoneState {
  isOpen: boolean
  phoneNumber: string
}

const initialState: WebPhoneState = {
  isOpen: false,
  phoneNumber: ''
}

const webPhoneSlice = createSlice({
  name: 'webPhone',
  initialState,
  reducers: {
    openWebPhone: (state, action: PayloadAction<{ phoneNumber?: string }>) => {
      state.isOpen = true
      state.phoneNumber = action.payload.phoneNumber || ''
    },
    closeWebPhone: (state) => {
      state.isOpen = false
      state.phoneNumber = ''
    },
    toggleWebPhone: (
      state,
      action: PayloadAction<{ phoneNumber?: string }>
    ) => {
      if (state.isOpen) {
        state.isOpen = false
        state.phoneNumber = ''
      } else {
        state.isOpen = true
        state.phoneNumber = action.payload.phoneNumber || ''
      }
    }
  }
})

export const { openWebPhone, closeWebPhone, toggleWebPhone } =
  webPhoneSlice.actions
export default webPhoneSlice.reducer
