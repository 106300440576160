import { useState } from 'react'
import Table4Columns from './EntitiesView/Table4Columns'

import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useGetBluredOtpMutation } from 'src/services/applications'

const columnsCode =
  process.env.REACT_APP_ENVIRONMENT !== 'KPK'
    ? [
        ['smscode', 'smscode_sent_at'],
        ['smscode_agreement', 'smscode_agreement_sent_at']
      ]
    : [
        ['smscode_application_loan', 'smscode_sent_at'],
        ['smscode_individual_terms_agreement', 'smscode_agreement_sent_at']
      ]

const fieldsDescription = {
  smscode: {},
  smscode_sent_at: { isDateTime: true },
  smscode_agreement: {},
  smscode_agreement_sent_at: { isDateTime: true }
}

export const Code = ({ id }: { id: string }) => {
  const { t } = useTranslation()

  const [code, setCode] = useState({})
  const [getCodes, { isError: error, isSuccess: success }] =
    useGetBluredOtpMutation()

  const getCode = async () => {
    await getCodes({ request_id: id })
      .unwrap()
      .then((res) => {
        setCode(res)
      })
  }

  return (
    <>
      {!success && (
        <Button
          disabled={error}
          color='danger'
          size='sm'
          style={{
            marginTop: '10px',
            marginBottom: '5px',
            maxWidth: '250px'
          }}
          onClick={getCode}
        >
          {t('view signing codes')}
        </Button>
      )}

      {error && <p>{t('dont have permission')}</p>}

      {success && (
        <Table4Columns
          columnsDescription={columnsCode}
          fieldsDescription={fieldsDescription}
          className='table-sm align-middle mb-0'
          style={{ width: 'max-content' }}
          entity={code}
        />
      )}
    </>
  )
}
