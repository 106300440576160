import {
  ApplicationFilters,
  HeaderStyle,
  TableTitle,
  TextAlign
} from '../types/applications.types'

export const valueAlign = (header: TableTitle) => {
  if (header.right) {
    return 'right'
  }

  return 'left'
}

export const headerStyle = (header: TableTitle): HeaderStyle => {
  let textAlign: TextAlign = 'start'

  if (header.right) {
    textAlign = 'end'
  }

  return { textAlign, whiteSpace: 'break-spaces' }
}

export const replaceEmptyStringsWithUndefined = (
  filters: Partial<ApplicationFilters> & { offset: number; limit: number }
) => {
  const result = { ...filters }

  for (const key in filters) {
    const filterField = key as keyof ApplicationFilters

    if (typeof result[filterField] === 'string' && result[filterField] === '') {
      delete result[filterField]
    }
  }

  return result
}

/*export const handleFilterErrors = (error: unknown) => {
  const { data } = error as ListError

  return data.detail
    .map((obj) => {
      const nameError = obj.loc[1]
      const msgError = obj.msg

      return `${nameError}: ${msgError}`
    })
    .join('; ')
}*/
