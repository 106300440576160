import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Input, Table, Label } from 'reactstrap'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import MetaBar from 'src/Components/Common/MetaBar'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import model from 'src/model'
import Client from 'src/client'
import { useGetCompanyNamesQuery } from 'src/services/companies'

export const typeTemplates = ['sms', 'email', 'phonerobot', 'chat', 'pop-up']

const headers = {
  id: {},
  title: {
    label: 'title_pop-up',
    options: ['pop-up'],
    style: {
      maxWidth: '300px',
      overflow: 'hidden',
      whiteSpace: 'wrap',
      wordWrap: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  template: {
    options: ['sms', 'phonerobot', 'chat', 'pop-up'],
    label: 'template',
    style: {
      maxWidth: '300px',
      overflow: 'hidden',
      whiteSpace: 'wrap',
      wordWrap: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  text_template: {
    options: ['email'],
    label: 'text template',
    style: {
      maxWidth: '1px',
      overflow: 'hidden',
      wordWrap: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  html_template: {
    options: ['email'],
    label: 'html template',
    style: {
      maxWidth: '1px',
      overflow: 'hidden',
      wordWrap: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  subject: { label: 'subject', options: ['email'] },
  display_frequency: {
    label: 'display_frequency',
    options: ['pop-up'],
    style: {
      maxWidth: '300px',
      overflow: 'hidden',
      whiteSpace: 'wrap',
      wordWrap: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  preferable_sender_id: {
    label: 'preferable_sender_id',
    options: ['sms', 'phonerobot', 'chat', 'email']
  },
  is_service: {
    label: 'is_service',
    options: ['sms', 'phonerobot', 'chat', 'email']
  }
}

const actionsEnabled = true
const enableDelete = false

export const Templates = () => {
  const { t } = useTranslation()
  const [state, setState] = useState([])
  const [type, setType] = useState('sms')
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)

  const { data: companyNames } = useGetCompanyNamesQuery({})

  useEffect(() => {
    if (type) {
      Client.getTemplates(type)
        .then((data) => setState(data))
        .catch((err) => setParamError(err))
        .finally(() => setPageReady(false))
    } else setParamError('No template id found')
  }, [type])

  useEffect(() => {
    if (!state) return
    setPageReady(true)
  }, [state])

  const title = t('templates')

  if (paramError) {
    return <ErrorPage title={title} error={paramError} />
  }

  if (!pageReady) {
    return <LoadingPage title={title} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <MetaBar createLink={'/strategy/templates-create'} />
          <Label for='type'>
            <b>{t('type')}:</b>
          </Label>
          <Input
            id='type'
            type='select'
            className='form-control form-control-sm'
            onChange={(e) => setType(e.target.value)}
            value={type}
            data-testid='template-list-view-select'
          >
            {typeTemplates.map((act) => {
              return <option key={act}>{act}</option>
            })}
          </Input>
          <div className='table-responsive table-card mt-4'>
            <Table hover className='table-sm align-middle table-nowrap mb-0'>
              <thead>
                <tr>
                  <th />
                  {Object.keys(headers).map((header) => {
                    const optionsValue = headers[header].options
                    if (optionsValue?.includes(type))
                      return (
                        <th className='ps-3' key={header} scope='col'>
                          {t(headers[header].label || header)}
                        </th>
                      )
                  })}
                  {actionsEnabled && <th scope='col'>{t('Actions')}</th>}
                </tr>
              </thead>
              <tbody>
                {state.map((temp) => {
                  return (
                    <tr key={temp.id}>
                      <td style={{ width: '1%' }}>
                        <Link
                          to={`/strategy/templates-view?type=${type}&id=${temp.id}`}
                          className='fw-medium'
                        >
                          <i className='ri-eye-line fs-15' />
                        </Link>
                      </td>
                      {Object.keys(headers).map((fieldName) => {
                        const optionsValue = headers[fieldName].options
                        companyNames?.forEach((el) => {
                          if (el.id === temp.preferable_sender_id)
                            temp.preferable_sender_id = el?.short_name
                        })
                        if (optionsValue?.includes(type))
                          return (
                            <td
                              style={{
                                ...headers[fieldName].style
                              }}
                              className='ps-3'
                              key={fieldName}
                            >
                              {model.fieldValueToText(fieldName, temp, headers)}
                            </td>
                          )
                      })}
                      {actionsEnabled && (
                        <td>
                          <div className='hstack gap-3 flex-wrap'>
                            <Link
                              to={`/strategy/templates-edit?type=${type}&id=${temp.id}`}
                              className='link-success fs-15'
                            >
                              <i className='ri-edit-2-line'></i>
                            </Link>
                            {enableDelete && (
                              <Link
                                to='#'
                                onClick={() => {}}
                                className='link-danger fs-15'
                              >
                                <i className='ri-delete-bin-line'></i>
                              </Link>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
