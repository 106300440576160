import React, { useState } from 'react'
import { Alert, Button, Input, Row, Spinner, Table } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useGetPodsQuery } from 'src/services/monitoring'
import { formatDateTime } from 'src/utils'

const Pods = () => {
  const { t } = useTranslation()

  const [name, setName] = useState('')
  const [sortOrder, setSortOrder] = useState('-')

  const {
    data: pods,
    isLoading,
    isFetching,
    error,
    refetch,
    fulfilledTimeStamp
  } = useGetPodsQuery()

  const fieldsDescription = [
    t('metadata_name'),
    t('owner_name'),
    t('status').charAt(0).toUpperCase() + t('status').slice(1),
    t('createdAt'),
    t('phase'),
    'Docker image'
  ]

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center mt-5 mb-5'>
        <Spinner color='primary' />
      </div>
    )
  }

  if (error) {
    return (
      <Alert
        color='warning'
        className='d-flex justify-content-center mt-5 mb-5'
      >
        <strong> {JSON.stringify(error)} </strong>
      </Alert>
    )
  }

  if (!pods || !fulfilledTimeStamp) {
    return null
  }

  const filteredPods = pods.filter((pod) => pod.metadata.name.includes(name))

  const sortedPods = [...filteredPods].sort((a, b) => {
    const dateA = new Date(a.status.containerStatuses.state.startedAt).getTime()
    const dateB = new Date(b.status.containerStatuses.state.startedAt).getTime()

    if (sortOrder === 'asc') {
      return dateB - dateA
    } else if (sortOrder === 'desc') {
      return dateA - dateB
    } else {
      return 0
    }
  })

  const clearInputs = () => {
    setName('')
    setSortOrder('-')
  }

  return (
    <div className='d-flex flex-column gap-30'>
      <Row className='d-flex ms-1 mb-1'>
        <Input
          type='text'
          className='form-control form-control-sm me-3'
          style={{ width: '200px', height: '30px' }}
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Input
          type='select'
          className='form-select form-select-sm me-3'
          style={{ width: '200px', height: '30px' }}
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value='-'>По умолчанию</option>
          <option value='desc'>desc</option>
          <option value='asc'>asc</option>
        </Input>
        <Button
          color='primary'
          className='me-3'
          style={{
            backgroundColor: '#405189',
            width: '200px',
            padding: '4px'
          }}
          onClick={clearInputs}
        >
          {t('Clear')}
        </Button>
        <Button
          color='primary'
          style={{
            backgroundColor: '#405189',
            width: '200px',
            padding: '4px'
          }}
          onClick={() => refetch()}
        >
          {t('refresh')}
        </Button>
      </Row>
      <h4 className='card-title mb-0 mt-4 ms-2'>
        {t('last_request_time')}:
        <span
          style={{ textUnderlineOffset: '2px' }}
          className='text-decoration-underline ps-2 pb-1'
        >
          {formatDateTime(fulfilledTimeStamp)}
        </span>
      </h4>

      {!isFetching ? (
        <Table
          hover
          className='table-sm align-middle table-nowrap mb-0 mt-4'
          responsive
        >
          <thead>
            <tr>
              {fieldsDescription.map((fieldName) => (
                <th className='ps-3' key={fieldName} scope='col'>
                  {fieldName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedPods.map((pod) => (
              <tr key={pod.metadata.name}>
                <td className='ps-3'>{pod.metadata.name}</td>
                <td className='ps-3'>{pod.metadata.ownerName}</td>
                <td className='ps-3'>
                  {pod.status.containerStatuses.state.status}
                </td>
                <td className='ps-3'>
                  {formatDateTime(pod.status.containerStatuses.state.startedAt)}
                </td>
                <td className='ps-3'>{pod.status.phase}</td>
                <td className='ps-3'>{pod.status.containerStatuses.image}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className='d-flex justify-content-center mt-5 mb-5'>
          <Spinner color='primary' />
        </div>
      )}
    </div>
  )
}

export default Pods
