import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Label, Form, Button, Alert } from 'reactstrap'

import Client from '../../../client'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

const typeTemplates = ['-', 'sms', 'email', 'phonerobot', 'pop-up']

export const InputItem = ({ strategy_id, updateComp, setUpdateComp }) => {
  const { t } = useTranslation()
  const [templates, setTemplates] = useState([])
  const [inputTypeActions, setInputTypeActions] = useState('-')
  const [template_id, setTemplate_id] = useState('-')
  const [successful, setSuccessful] = useState(false)
  const [paramError, setParamError] = useState(null)

  useEffect(() => {
    if (inputTypeActions === '-') {
      setTemplates([])
      setTemplate_id('-')
    }
    if (inputTypeActions !== '-') {
      Client.getTemplates(inputTypeActions)
        .then((data) => setTemplates(data))
        .catch((err) => paramError(err))
    } else {
      setTemplates([])
      setTemplate_id('-')
    }
  }, [inputTypeActions])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { inputTypeActions, template_id },
    validationSchema: Yup.object({}),
    onSubmit: () => {
      console.log('TEMP', template_id)
      Client.addActionStrategy(strategy_id, inputTypeActions, { template_id })
        .then(() => setSuccessful(true), setUpdateComp(!updateComp))
        .catch((err) => {
          setSuccessful(false)
          setParamError(err)
        })
    }
  })

  return (
    <Form
      className='needs-validation mt-4'
      onSubmit={(e) => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      <Label for='type'>{t('type')}</Label>
      <Input
        className='form-control form-control-sm mb-2'
        type='select'
        id='type'
        onChange={(e) => setInputTypeActions(e.target.value)}
        defaultValue={inputTypeActions}
      >
        {typeTemplates.map((act) => {
          return <option key={act}>{act}</option>
        })}
      </Input>
      <Label for='templates'>{t('templates')}</Label>
      <Input
        className='form-control form-control-sm mb-2'
        type='select'
        disabled={templates.length === 0 ? true : false}
        id='templates'
        onChange={(e) => {
          setTemplate_id(Number(e.target.value))
        }}
      >
        <option>-</option>
        {templates.length === 0
          ? null
          : templates.map((act) => {
              return (
                <option key={act.id} value={act.id}>
                  {act.template}
                </option>
              )
            })}
      </Input>
      {!!paramError && (
        <Alert color='warning'>
          <strong> {paramError.toString()} </strong>
        </Alert>
      )}
      {successful && (
        <Alert color='success'>
          <strong> {t('Operation success')} </strong>
        </Alert>
      )}
      <Button
        color='primary'
        style={{ backgroundColor: '#405189' }}
        type='submit'
        disabled={
          inputTypeActions === '-' || template_id === (null || '-')
            ? true
            : false
        }
      >
        {t('Create')}
      </Button>
    </Form>
  )
}
