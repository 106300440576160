import { Col, Container } from 'reactstrap'
import Table4Columns from 'src/Components/Common/EntitiesView/Table4Columns'
import { Loan } from 'src/services/loans'

const columnsDescriptionMain = [
  ['next_payment_date', 'next_payment_amount'],
  ['total_amount_owed', 'repayment_date'],
  ['overdue_payment_periods']
]

const fieldsDescriptionMain = {
  next_payment_amount: { isAmount: true },
  created_at: { fieldName: 'created_at', isDate: true },
  next_payment_date: {
    fieldName: 'next_schedule_payment_date',
    label: 'next_payment_date',
    isDate: true,
    mappingClassName: () => ''
  },
  repayment_date: { fieldName: 'repayment_date', isDate: true },
  issue_date: { fieldName: 'issue_date', isDate: true },
  closing_date: { fieldName: 'closing_date', isDate: true },
  amount_debt: { isAmount: true },
  total_amount_owed: {
    fieldName: 'overdue_payment_amount',
    label: 'total_amount_owed',
    isAmount: true
  },
  interest: { label: 'loan_interest', isAmount: true, decimals: 5 },
  term: { label: 'approved_period' },
  penalty_pending: {
    label: 'penalty_pending',
    isAmount: true,
    decimals: 5
  }
}

const IlTable = ({ entity }: { entity: Loan }) => {
  fieldsDescriptionMain.next_payment_date.mappingClassName = () =>
    entity.dpd && entity.dpd > 0 ? 'text-danger' : ''

  return (
    <Col xxl='9' xl='12' className='order-2 order-xxl-1 mt-3'>
      <Container fluid>
        <h6>Данные по платежу</h6>
        <Table4Columns
          style={{}}
          className='table-sm align-middle mb-0'
          columnsDescription={columnsDescriptionMain}
          fieldsDescription={fieldsDescriptionMain}
          entity={{ ...entity }}
        />
      </Container>
    </Col>
  )
}

export default IlTable
