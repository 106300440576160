import { TableTitle } from '../types/applications.types'

export const COLUMNS_DESCRIPTION = [
  ['application_status', 'product_code'],
  ['new_line'],
  ['requested_amount', 'requested_period'],
  ['approved_amount', 'approved_period'],
  ['signed_amount', 'signed_period']
]

export const HEADERS: Record<string, TableTitle> = {
  id: { name: '' },
  client: { name: 'client' },
  mobile: { name: 'mobile' },
  email: { name: 'email' },
  status: { name: 'status' },
  updated_at: { name: 'created_at' },
  stage: { name: 'stage' },
  requested_amount: { name: 'requested_amount', right: true },
  requested_period: { name: 'requested_period', right: true },
  approved_amount: { name: 'approved_amount', right: true },
  approved_period: { name: 'approved_period', right: true },
  signed_amount: { name: 'signed_amount', right: true },
  signed_period: { name: 'signed_period', right: true },
  verificator_surname: { name: 'verificator_surname', right: true },
  is_repeated: { name: 'is_repeated', right: true },
  product_copy: { name: 'product_type', right: true }
}

export const ENABLE_ACTIONS = false
export const ENABLE_EDIT = false
export const ENABLE_DELETE = false
