import React, { useEffect, useState } from 'react'
import { Alert, Button, Input, Row, Spinner, Table } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useGetDeploymentsQuery } from 'src/services/monitoring'
import { formatAge, formatDateTime } from 'src/utils'

const Deployments = () => {
  const { t } = useTranslation()

  const [controllerName, setControllerName] = useState('')
  const [namespaces, setNamespaces] = useState<string[]>([])
  const [selectedNamespace, setSelectedNamespace] = useState<string>('')
  const [sortOrder, setSortOrder] = useState('-')

  const {
    data: deployments,
    isLoading,
    isFetching,
    error,
    refetch,
    fulfilledTimeStamp
  } = useGetDeploymentsQuery()

  const fieldsDescription = [
    t('metadata_name'),
    t('namespace'),
    t('ready'),
    t('number_of_pods'),
    t('controller_age')
  ]

  useEffect(() => {
    if (deployments) {
      const uniqueNamespaces = new Set(
        deployments.map((d) => d.metadata.namespace)
      )

      setNamespaces(Array.from(uniqueNamespaces))
      setSelectedNamespace(Array.from(uniqueNamespaces)[0])
    }
  }, [deployments])

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center mt-5 mb-5'>
        <Spinner color='primary' />
      </div>
    )
  }

  if (error) {
    return (
      <Alert
        color='warning'
        className='d-flex justify-content-center mt-5 mb-5'
      >
        <strong> {JSON.stringify(error)} </strong>
      </Alert>
    )
  }

  if (!deployments || !fulfilledTimeStamp) {
    return null
  }

  const filteredDeployments = deployments.filter((item) => {
    const matchesName = item.metadata.name
      .toLowerCase()
      .includes(controllerName.toLowerCase())
    const matchesNamespace = item.metadata.namespace === selectedNamespace

    return matchesName && matchesNamespace
  })

  const sortedDeployments = [...filteredDeployments].sort((a, b) => {
    const dateA = new Date(a.metadata.creationTimestamp).getTime()
    const dateB = new Date(b.metadata.creationTimestamp).getTime()

    if (sortOrder === 'asc') {
      return dateB - dateA
    } else if (sortOrder === 'desc') {
      return dateA - dateB
    } else {
      return 0
    }
  })

  const clearInputs = () => {
    setControllerName('')
    setSortOrder('-')
  }

  return (
    <div className='d-flex flex-column gap-30'>
      <Row className='d-flex ms-1'>
        <Input
          type='text'
          className='form-control form-control-sm me-3'
          style={{ width: '200px', height: '30px' }}
          onChange={(e) => setControllerName(e.target.value)}
          value={controllerName}
        />
        <Input
          type='select'
          className='form-select form-select-sm me-3'
          style={{ width: '200px', height: '30px' }}
          value={selectedNamespace}
          onChange={(e) => setSelectedNamespace(e.target.value)}
        >
          {namespaces.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Input>
        <Input
          type='select'
          className='form-select form-select-sm me-3'
          style={{ width: '200px', height: '30px' }}
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value='-'>По умолчанию</option>
          <option value='desc'>desc</option>
          <option value='asc'>asc</option>
        </Input>
        <Button
          color='primary'
          className='me-3'
          style={{
            backgroundColor: '#405189',
            width: '200px',
            padding: '4px'
          }}
          onClick={clearInputs}
        >
          {t('Clear')}
        </Button>
        <Button
          color='primary'
          style={{
            backgroundColor: '#405189',
            width: '200px',
            padding: '4px'
          }}
          onClick={() => refetch()}
        >
          {t('refresh')}
        </Button>
      </Row>
      <h4 className='card-title mb-0 mt-4 ms-2'>
        {t('last_request_time')}:
        <span
          style={{ textUnderlineOffset: '2px' }}
          className='text-decoration-underline ps-2 pb-1'
        >
          {formatDateTime(fulfilledTimeStamp)}
        </span>
      </h4>

      {!isFetching ? (
        <Table
          hover
          responsive
          className='table-sm align-middle table-nowrap mb-0 mt-4'
        >
          <thead>
            <tr>
              {fieldsDescription.map((fieldName) => (
                <th className='ps-3' key={fieldName} scope='col'>
                  {fieldName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedDeployments.map((d) => (
              <tr key={d.metadata.name}>
                <td className='ps-3'>{d.metadata.name}</td>
                <td className='ps-3'>{d.metadata.namespace}</td>
                <td style={{ paddingLeft: '60px' }}>
                  {d.status.readyReplicas || 0} / {d.status.replicas || 0}
                </td>
                <td style={{ paddingLeft: '110px' }}>
                  {d.status.updatedReplicas || 0}
                </td>
                <td className='ps-3'>
                  {formatAge(d.metadata.creationTimestamp)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className='d-flex justify-content-center mt-5 mb-5'>
          <Spinner color='primary' />
        </div>
      )}
    </div>
  )
}

export default Deployments
