import { api } from 'src/services/api'

interface MembershipResponse {
  id: string
  begin_date: string
  end_date: string | null
  fees_accrued: number
  membership_active_fees_accrued: number
  membership_passive_fees_accrued: number
}

export const membershipApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMembershipStatus: builder.query<MembershipResponse[], any>({
      query: (profile_id) => `crm/v1/profiles/${profile_id}/kpk-history`
    })
  })
})

export const { useGetMembershipStatusQuery } = membershipApi
