import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Message } from 'src/services/calling'

interface CallingState {
  lastMessage: Message | null
  isReadyToCall: boolean
}

const initialState: CallingState = {
  lastMessage: null,
  isReadyToCall: false
}

const callingSlice = createSlice({
  name: 'calling',
  initialState,
  reducers: {
    setLastMessage(state, action: PayloadAction<Message>) {
      state.lastMessage = action.payload
    },
    setIsReadyToCall(state, action: PayloadAction<boolean>) {
      state.isReadyToCall = action.payload
    }
  }
})

export const { setLastMessage, setIsReadyToCall } = callingSlice.actions
export default callingSlice.reducer
