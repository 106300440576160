import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, Input } from 'reactstrap'
import { Link } from 'react-router-dom'

import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import List from 'src/Components/Common/EntitiesView/List'
import { useGetListSettingQuery } from 'src/services/redirectSettings'
import { useEffect, useState } from 'react'

export const RedirectSettings = () => {
  const [filtredArray, setFiltredArray] = useState<any>([])
  const [clientType, setClientType] = useState<string>('')
  const [pdn, setPdn] = useState<string>('')
  const [mpl, setMpl] = useState<string>('')
  const { t } = useTranslation()

  const {
    data: redirectList,
    error,
    isLoading,
    refetch
  } = useGetListSettingQuery({
    limit: 500,
    offset: 0
  })

  const title = t('Redirect settings')

  useEffect(() => {
    if (redirectList) {
      const array: any = redirectList
        ?.filter((item) => {
          if (clientType.length) {
            return item.client_type.toLowerCase() === clientType.toLowerCase()
          } else {
            return true
          }
        })
        .filter((item) => {
          if (pdn.length) {
            return item.pdn.toLowerCase() === pdn.toLowerCase()
          } else {
            return true
          }
        })
        .filter((item) => {
          if (mpl.length) {
            return item.mpl.toLowerCase() === mpl.toLowerCase()
          } else {
            return true
          }
        })

      setFiltredArray(array)
    }
  }, [redirectList, clientType, pdn, mpl])

  if (isLoading) return <LoadingPage />

  if (error) return <ErrorPage title={title} error={error} />

  return (
    <div className='page-content'>
      <BreadCrumb title={title} pageTitle={undefined} link={undefined} />
      <Card>
        <CardBody>
          <div className='d-flex flex-row mb-5 align-items-end'>
            <div>
              <label htmlFor='client-type'>{t('client type')}</label>
              <Input
                id='client-type'
                type='select'
                className='form-select form-select-sm me-3'
                style={{ width: '200px', height: '40px' }}
                value={clientType}
                onChange={(e) => {
                  setClientType(e.target.value)
                }}
              >
                <option value=''>-</option>
                <option value='не учитываем'>Не учитываем</option>
                <option value='новый'>Новый</option>
                <option value='повторный'>Повторный</option>
              </Input>
            </div>

            <div>
              <label>{t('pdn')}</label>
              <Input
                type='select'
                className='form-control form-control-sm me-3'
                style={{ width: '200px', height: '40px' }}
                value={pdn}
                onChange={(e) => {
                  setPdn(e.target.value)
                }}
              >
                <option value=''>-</option>
                <option value='ПДН <= 50,0%'>{`ПДН <= 50,0%`}</option>
                <option value='50,0% > ПДН <=80,0%'>{`50,0% > ПДН <=80,0%`}</option>
                <option value='ПДН > 80,0%'>{`ПДН > 80,0%`}</option>
              </Input>
            </div>

            <div>
              <label>{t('mpl')}</label>
              <Input
                type='select'
                className='form-control form-control-sm me-3'
                style={{ width: '350px', height: '40px' }}
                value={mpl}
                onChange={(e) => {
                  setMpl(e.target.value)
                }}
              >
                <option value=''>-</option>
                <option value='не учитываем'>{`не учитываем`}</option>
                <option value='Сегмент МПЛ "50,0% < ПДН <=80,0%" <= 9.5%'>{`Сегмент МПЛ "50,0% < ПДН <=80,0%" <= 9.5%`}</option>
                <option value='Сегмент МПЛ "50,0% < ПДН <=80,0%" > 9.5%'>{`Сегмент МПЛ "50,0% < ПДН <=80,0%" > 9.5%`}</option>
                <option value='Сегмент МПЛ "ПДН > 80,0%" < 2.5%'>{`Сегмент МПЛ "ПДН > 80,0%" < 2.5%`}</option>
              </Input>
            </div>

            <div>
              <Button
                type='button'
                style={{ width: '200px', height: '40px' }}
                onClick={() => {
                  setFiltredArray(redirectList)
                  setClientType('')
                  setPdn('')
                  setMpl('')
                  refetch()
                }}
              >
                Сбросить фильтры
              </Button>
            </div>
          </div>

          {filtredArray.length > 0 && (
            <List
              list={filtredArray.map((field: any) => {
                return {
                  ...field
                }
              })}
              className={undefined}
              style={{ width: 'fit-content' }}
              fieldsDescription={{
                pdn: {},
                mpl: {},
                client_type: { label: 'client type' },
                kpk_status: { label: 'status KPK' },
                interval_min: { label: 'interval min' },
                interval_max: { label: 'interval max' },
                result_status: { label: 'result status' },
                company: {}
              }}
              hover
              actions={filtredArray.map((field: any) => {
                return (
                  <Link
                    key={field.id}
                    to={`/redirect-settings-edit?id=${field.id}`}
                    className='link-success fs-15'
                  >
                    <i className='ri-edit-2-line'></i>
                  </Link>
                )
              })}
            />
          )}
        </CardBody>
      </Card>
    </div>
  )
}
