import { FC } from 'react'
import { Card, Table } from 'reactstrap'
import { useGetMembershipStatusQuery } from 'src/services/kpk/membership'
import Loader from '../Common/Loader'
import ErrorPage from 'src/pages/Admin/ErrorPage'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'src/utils'

interface MembershipInfoProps {
  productCopy?: any
  profileId: string
}

export const MembershipInfo: FC<MembershipInfoProps> = ({
  productCopy,
  profileId
}) => {
  const { t } = useTranslation()
  const {
    data: membership,
    isLoading: loading,
    isError: error
  } = useGetMembershipStatusQuery(profileId, {
    skip: !profileId
  })

  if (loading) return <Loader />
  if (error) return <ErrorPage title={'Error'} />

  return (
    <div>
      {membership?.map((item) => (
        <Card key={item.id}>
          <Table className={'table-sm align-middle mb-0'}>
            <tbody>
              <tr>
                <td>{t('Date of joining the shareholders')}</td>
                <td>{formatDate(item?.begin_date) ?? '-'}</td>
              </tr>
              <tr>
                <td>{t('Date of leaving the shareholders')}</td>
                <td>{(item.end_date && formatDate(item?.end_date)) ?? '-'}</td>
              </tr>
              <tr>
                <td>{t('Reason for termination of membership')}</td>
                <td>-</td>
              </tr>
              <tr>
                <td>{t('Share accumulation amount')}</td>
                <td>{item?.fees_accrued ?? '-'}</td>
              </tr>
              <tr>
                <td>{t('membership_active_fees_accrued')}</td>
                <td>{item?.membership_active_fees_accrued ?? '-'}</td>
              </tr>
              <tr>
                <td>{t('membership_passive_fees_accrued')}</td>
                <td>{item?.membership_passive_fees_accrued ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
      ))}
      {/* КОСТЫЛЬ ПОТОМУ ЧТО ТАК НАДО, А НЕ ПОТОМУ ЧТО Я ЛЕНИВЫЙ */}
      {productCopy && (
        <Card>
          <Table className={'table-sm align-middle mb-0'}>
            <tbody>
              <tr>
                <td>{t('kpk_fee')}</td>
                <td>{productCopy?.kpk_fee}</td>
              </tr>
              <tr>
                <td>{t('kpk_extension_fee')}</td>
                <td>{productCopy?.kpk_extension_fee}</td>
              </tr>
              <tr>
                <td>{t('kpk_pending_fee')}</td>
                <td>{productCopy?.kpk_pending_fee}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
      )}
    </div>
  )
}
