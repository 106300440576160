import { api } from './api'

type DeploymentMetadata = {
  creationTimestamp: Date
  generation: number
  name: string
  namespace: string
}

type ReplicaSetMetadata = {
  name: string
  namespace: string
  creationTimestamp: Date
  ownerReferences: {
    kind: string
    name: string
  }[]
}

type CronJobMetadata = {
  name: string
  namespace: string
  creationTimestamp: Date
  ownerRefKind: string
  ownerRefName: string
}

type PodsMetadata = {
  name: string
  namespace: string
  ownerName: string
  ownerRefKind: string
}

type DeploymentCondition = {
  status: string
  lastTransitionTime: Date
  lastUpdateTime: Date
  type: string
  message: string
  reason: string
}

type CronJobCondition = {
  status: string
  lastTransitionTime: Date
  lastProbeTime: Date
  type: string
  message: string
  reason: string
}

type PodsCondition = {
  lastTransitionTime: Date
  message: string
  reason: string
  status: string
  type: string
}

type DeploymentStatus = {
  availableReplicas: number
  conditions: DeploymentCondition[]
  observedGeneration: number
  readyReplicas: number
  replicas: number
  updatedReplicas: number
}

type CronJobStatus = {
  startTime: Date
  completionTime: Date
  failed: number
  ready: number
  active: number
  succeeded: number
  terminating: number
  conditions: CronJobCondition[]
}

type TerminatedPods = {
  containerId: string
  exitCode: number
  finishedAt: Date
  message?: string
  reason: string
  signal?: string
  startedAt: Date
}

type PodsStatus = {
  conditions: PodsCondition[]
  containerStatuses: {
    image: string
    state: {
      startedAt: Date
      status: string
    }
    lastState: {
      running: unknown
      waiting: unknown
      terminated: TerminatedPods
    }
  }
  phase: string
}

type DeploymentResponse = {
  metadata: DeploymentMetadata
  status: DeploymentStatus
}

type ReplicaSetResponse = {
  metadata: ReplicaSetMetadata
  status: {
    replicas: number
    observedGeneration: number
  }
}

type CronJobResponse = {
  metadata: CronJobMetadata
  status: CronJobStatus
}

type PodsResponse = {
  metadata: PodsMetadata
  status: PodsStatus
}

const monitoringApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDeployments: builder.query<DeploymentResponse[], void>({
      query: () => ({
        url: 'k8s-client/v1/deployments'
      })
    }),
    getPods: builder.query<PodsResponse[], void>({
      query: () => ({ url: 'k8s-client/v1/pods' })
    }),
    getCronJobs: builder.query<CronJobResponse[], void>({
      query: () => ({
        url: 'k8s-client/v1/cron-jobs'
      })
    }),
    getReplicas: builder.query<ReplicaSetResponse[], void>({
      query: () => ({
        url: 'k8s-client/v1/replica-sets'
      })
    })
  })
})

export const {
  useGetDeploymentsQuery,
  useGetPodsQuery,
  useGetCronJobsQuery,
  useGetReplicasQuery
} = monitoringApi
