import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import { RootState, useDispatch, useSelector } from 'src/store'
import { setIsReadyToCall } from 'src/store/calling/calling-slice'
import { LoanView } from '../Loans/View'
import { Link, useSearchParams } from 'react-router-dom'
import { ChangeEvent, useEffect, useState } from 'react'
import { useGetCalledTodayLoansQuery } from 'src/services/calling'
import LoadingPage from '../LoadingPage'
import ErrorPage, { DangerAlert } from '../ErrorPage'
import {
  useGetUserInfoQuery,
  useSetUserStatusMutation
} from 'src/services/keycloak'

const STATUS_READY = 'Готов к работе'
const STATUS_PAUSE = 'Перерыв/не на месте'

export const AutomatedCalling = () => {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const currentId = searchParams.get('id')

  const {
    data: loanIdsData = [],
    isLoading,
    error
  } = useGetCalledTodayLoansQuery({ offset: 0, limit: 50 })

  const [loanIdsLocal, setLoanIdsLocal] = useState<string[]>([])

  const dispatch = useDispatch()
  const lastMessage = useSelector(
    (state: RootState) => state.calling.lastMessage
  )
  const isReadyToCall = useSelector(
    (state: RootState) => state.calling.isReadyToCall
  )

  const { data: user } = useGetUserInfoQuery()
  const [
    setUserStatus,
    { isLoading: isLoadingUserStatus, error: userStatusError }
  ] = useSetUserStatusMutation()

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    if (isChecked) {
      try {
        await setUserStatus(STATUS_READY)
        dispatch(setIsReadyToCall(true))
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        await setUserStatus(STATUS_PAUSE)
        dispatch(setIsReadyToCall(false))
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    if (user?.status === STATUS_READY) {
      dispatch(setIsReadyToCall(true))
    } else {
      dispatch(setIsReadyToCall(false))
    }
  }, [user?.status, dispatch])

  useEffect(() => {
    setLoanIdsLocal(loanIdsData)
  }, [loanIdsData])

  useEffect(() => {
    const hasId = searchParams.get('id')
    if (!hasId && loanIdsLocal?.length > 0) {
      setSearchParams({ id: loanIdsLocal[0] })
    }
  }, [loanIdsLocal, searchParams, setSearchParams])

  useEffect(() => {
    if (lastMessage?.loan_id) {
      setLoanIdsLocal((prev) => {
        const loanIdAsString = String(lastMessage.loan_id)
        if (!prev.includes(loanIdAsString)) {
          return [...prev, loanIdAsString]
        }
        return prev
      })
    }
  }, [lastMessage])

  const currentIndex = loanIdsLocal?.findIndex((loanId) => loanId === currentId)

  const prevIndex =
    currentIndex > 0 ? currentIndex - 1 : loanIdsLocal.length - 1

  const nextIndex =
    currentIndex < loanIdsLocal.length - 1 ? currentIndex + 1 : 0

  const isPrevDisabled = currentIndex <= 0
  const isNextDisabled = currentIndex >= loanIdsLocal.length - 1

  const title = `${t('Automated-calling')}`
  document.title = title

  if (isLoading) {
    return <LoadingPage title={title} />
  }

  if (error) {
    return <ErrorPage title={title} error={error} />
  }

  return (
    <div className='page-content'>
      <BreadCrumb title={title} />
      <Card>
        <CardBody>
          <FormGroup className='d-flex gap-2'>
            <Label
              style={isLoadingUserStatus ? { color: '#6c757d' } : {}}
              for='is_active'
            >
              {t('Ready_to_call')}
            </Label>
            <Input
              id='is_active'
              name='is_active'
              type='checkbox'
              checked={isReadyToCall}
              onChange={handleChange}
              disabled={isLoadingUserStatus}
            />
          </FormGroup>
          {userStatusError && <DangerAlert error={userStatusError} />}
          {loanIdsLocal && loanIdsLocal.length > 0 && (
            <div>
              <div style={{ marginBottom: '-40px' }} className='d-flex gap-2'>
                <Link
                  style={isPrevDisabled ? { pointerEvents: 'none' } : {}}
                  to={`/automated-calling?id=${loanIdsLocal[prevIndex]}`}
                >
                  <Button
                    color='primary'
                    disabled={isPrevDisabled}
                    style={{ backgroundColor: '#405189' }}
                  >
                    <i className='ri-arrow-left-line label-icon align-middle fs-16 me-2'></i>
                  </Button>
                </Link>
                <Link
                  style={isNextDisabled ? { pointerEvents: 'none' } : {}}
                  to={`/automated-calling?id=${loanIdsLocal[nextIndex]}`}
                >
                  <Button
                    color='primary'
                    style={{ backgroundColor: '#405189' }}
                    disabled={isNextDisabled}
                  >
                    <i className='ri-arrow-right-line label-icon align-middle fs-16 me-2'></i>
                  </Button>
                </Link>
              </div>
              <LoanView />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
