import { FC, useEffect, useState } from 'react'
import { Container, Col } from 'reactstrap'
import Table4Columns from '../Common/EntitiesView/Table4Columns'
import Widget from '../Common/EntitiesView/LoanWidget'

interface LoanTableProps {
  hasData?: boolean
  entity: any
}

const columnsDescriptionHead = [
  ['org_origin', 'product_type'],
  ['org_owner', 'product_code'],
  ['org_collect', 'product_name'],
  ['kpk_fee'],
  ['kpk_extension_fee'],
  ['kpk_pending_fee']
]

const columnsDescriptionMain = [
  ['status', 'issue_date'],
  ['short_name', 'repayment_date'],
  ['created_at', 'closing_date'],
  ['loan_issue_card', 'interest'],
  //amount debt заменить на новое поле, для учета с ЧВ
  ['amount_debt', 'dpd'],
  ['term', 'interest_frozen_end_date'],
  ['debt_kpk_fee', 'penalties_frozen_end_date'],
  ['kpk_fee_debt'],
  ['penalty_pending', 'prolongation_count'],
  ['extension_available', '']
]

const fieldsDescriptionMain = (productCode?: string) => ({
  created_at: { fieldName: 'created_at', isDate: true },
  repayment_date: { fieldName: 'repayment_date', isDate: true },
  issue_date: { fieldName: 'issue_date', isDate: true },
  closing_date: { fieldName: 'closing_date', isDate: true },
  amount_debt: {
    label: 'amount_debt',
    fieldName: productCode === 'IL' ? 'repayment_today' : 'amount_debt',
    isAmount: true
  },
  repayment_today: { isAmount: true },
  interest: { label: 'loan_interest', isAmount: true, decimals: 5 },
  term: { label: 'approved_period' },
  penalty_pending: {
    label: 'penalty_pending',
    isAmount: true,
    decimals: 5
  }
})

const columnsDescriptionOperations = [
  ['', 'amount_debt'],
  ['accrued_principal', 'debt_principal'],
  ['accrued_interest', 'debt_interest'],
  ['accrued_penalty', 'debt_penalty'],
  ['paid_principal', 'writeoff_principal'],
  ['paid_interest', 'writeoff_interest'],
  ['paid_penalty', 'writeoff_penalty'],
  ['paid_overpayment']
]

const configWidget = {
  amount_principal_debt: { color: '#ed5e5e' }, // red
  amount_interest_debt: { color: '#02d91b' }, // green
  amount_penalty_debt: { color: '#d408a4' }, // purpule
  amount_principal_paid: { color: '#0c4bb0' }, // blue
  amount_interest_paid: { color: '#5ce66c' }, // green
  amount_penalty_paid: { color: '#cf63b5' }, // purpule
  amount_principal_writeoff: { color: '#021f4d' }, // black
  amount_interest_writeoff: { color: '#075710' }, // green
  amount_penalty_writeoff: { color: '#4a0b3b' }, // black purpule
  amount_paid_overpayment: { color: '#d0fe1d' } //lime
}

const generateOperationsData = (
  entity: any,
  operationTypes: string[],
  categories: string[],
  format: 'table' | 'widget'
) => {
  const initialResult = categories.reduce((acc, category) => {
    operationTypes.forEach((type) => {
      if (format === 'widget') {
        //@ts-ignore
        acc[`amount_${type.toLowerCase()}_${category}`] = 0
      } else {
        //@ts-ignore
        acc[`${category}_${type.toLowerCase()}`] = 0
      }
    })
    return acc
  }, {})

  const result = { ...initialResult }

  categories.forEach((category) => {
    entity?.operations?.[category]?.forEach((value: any) => {
      const key =
        format === 'widget'
          ? `amount_${value.operation_type.toLowerCase()}_${category}`
          : `${category}_${value.operation_type.toLowerCase()}`
      //@ts-ignore
      if (result[key] !== undefined) {
        //@ts-ignore
        result[key] = value.amount
      }
    })
  })

  return result
}

export const LoanTableKPK: FC<LoanTableProps> = ({ entity }) => {
  const [operationsList, setOperationsList] = useState({})
  const [widgetData, setWidgetData] = useState({})

  useEffect(() => {
    const operationTypes = ['principal', 'interest', 'penalty', 'overpayment']
    const categories = ['accrued', 'debt', 'paid', 'writeoff']

    const tableData = generateOperationsData(
      entity,
      operationTypes,
      categories,
      'table'
    )
    setOperationsList(tableData)

    const widgetData = generateOperationsData(
      entity,
      operationTypes,
      categories,
      'widget'
    )
    setWidgetData(widgetData)
  }, [entity])

  if (!entity) return <Container fluid>Нет данных</Container>

  // Principal - од
  // Interest - проценты
  // Penalty - штрафы
  // Overpayment - переплата
  // =======
  // accurued начислено
  // debt долг
  // paid оплачено
  // writeoff списано

  return (
    <>
      <Col xxl='8' xl='12' className='order-2 order-xxl-1'>
        <Container fluid>
          <h6>Данные по компании / продукту</h6>
          <Table4Columns
            style={{}}
            className='table-sm align-middle mb-0'
            columnsDescription={columnsDescriptionHead}
            fieldsDescription={[]}
            entity={{
              ...entity
              // ...entity.product_copy
            }}
          />
        </Container>
        <Container className='mt-3' fluid>
          <h6>Данные по займу</h6>
          <Table4Columns
            style={{}}
            className='table-sm align-middle mb-0'
            columnsDescription={columnsDescriptionMain}
            fieldsDescription={fieldsDescriptionMain(
              entity?.product_copy?.product_code
            )}
            entity={{
              ...entity
            }}
          />
        </Container>
        <Container className='mt-3' fluid>
          <h6>Операции</h6>
          <Table4Columns
            style={{}}
            className='table-sm align-middle mb-0'
            columnsDescription={columnsDescriptionOperations}
            fieldsDescription={[]}
            entity={{
              ...operationsList
            }}
          />
        </Container>
      </Col>
      <Col
        xxl='4'
        xl='12'
        className='order-1 order-xxl-2 d-flex align-items-center justify-content-center'
      >
        {!!entity && (
          <Widget
            loan={{ ...entity, ...widgetData }}
            style={{ marginLeft: -70 }}
            //@ts-ignore
            config={configWidget}
          />
        )}
      </Col>
    </>
  )
}
