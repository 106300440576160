import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetPaymentScheduleQuery } from 'src/services/payments'
import List from './List'
import Loader from '../Loader'

type Props = {
  entityId: string
  visible: boolean
  entityType: string
  icon: unknown | null
  title: string
}

export const PaymentSchedule: FC<Props> = ({ entityId, visible }) => {
  const { t } = useTranslation()

  const {
    data: payments,
    isFetching,
    isSuccess
  } = useGetPaymentScheduleQuery(entityId, {
    skip: entityId == null || !visible
  })

  return (
    <>
      {isFetching && <Loader />}
      {isSuccess && payments?.length ? (
        <List
          list={payments.map((item: any) => ({
            ...item
          }))}
          className={undefined}
          fieldsDescription={{
            payment_date: { isDate: true },
            is_paid: {
              mappingValue: (value: boolean | null) =>
                value ? t('Yes') : t('No')
            },
            payment_amount: {},
            interest_payment: {},
            principal_payment: {}
          }}
          style={undefined}
          hover
          actions
        />
      ) : (
        <div style={{ marginBottom: '10px' }}>
          <dl className='row mb-0'>
            <small>{t('No data')}</small>
          </dl>
        </div>
      )}
    </>
  )
}
