import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Label, Input, Form, FormGroup, FormFeedback } from 'reactstrap'
import Loader from '../Loader'
import List from './List'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  useGetBlockReasonsQuery,
  useGetBlockHistoryQuery,
  useBlockClientMutation,
  useUnblockClientMutation,
  BlockHistory
} from 'src/services/applications'

type BlockClientProps = {
  application: any
  className: string
}

const fieldsDescription = {
  operation_id: {
    label: 'Action',
    mappingValue: (value: number | null) =>
      value === 1 ? t('blocked') : t('unblocked')
  },
  created_at: { label: 'Action date', isDate: true },
  reason: { label: 'Reason' },
  username: { label: 'Login' },
  comment: { label: 'Comment' }
}

export const BlockClient: FC<BlockClientProps> = ({
  application,
  className
}) => {
  const { t } = useTranslation()
  const [id, setId] = useState(null)

  useEffect(() => {
    if (!application) return

    if (window.location.pathname === '/client-view') {
      setId(application.id)
    } else if (window.location.pathname === '/application-view') {
      setId(application.client_id)
    } else if (window.location.pathname === '/profile-view') {
      setId(application.client_id)
    } else if (window.location.pathname === '/verification-applications') {
      setId(application.client_id)
    }
  }, [application])

  const {
    data: reasons = [],
    isLoading: reasonsIsLoading,
    error: reasonsError
  } = useGetBlockReasonsQuery()

  const {
    data: history = [],
    isLoading: historyIsLoading,
    error: historyError
  } = useGetBlockHistoryQuery({ client_id: id }, { skip: !id })

  const [
    blockClient,
    { isLoading: blockIsLoading, error: blockError, isError: blockIsError }
  ] = useBlockClientMutation()

  const [
    unblockClient,
    {
      isLoading: unblockIsLoading,
      error: unblockError,
      isError: unblockIsError
    }
  ] = useUnblockClientMutation()

  const blocked = useMemo((): BlockHistory | false => {
    if (!history || history.length === 0) {
      return false
    }

    const lastRecord = history[history.length - 1]
    return lastRecord.operation_id === 1 ? lastRecord : false
  }, [history])

  const formik = useFormik({
    initialValues: {
      comment: '',
      reason_id: -1
    },
    validationSchema: Yup.object().shape({
      reason_id: blocked
        ? Yup.number()
        : Yup.number().required(t('Required')).notOneOf([-1], t('Required'))
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (blocked) {
        await unblockClient({
          client_id: id!,
          comment: values.comment
        })
      } else {
        await blockClient({
          client_id: id!,
          reason_id: values.reason_id!,
          comment: values.comment
        })
      }
      formik.resetForm()
    }
  })

  if (historyIsLoading) return <Loader />
  if (!application)
    return <DangerAlert error={`application equal: ${application}`} />

  return (
    <div className={className}>
      <div>
        <div className='mb-3'>
          {t('Status')}: {t(blocked ? 'blocked' : 'not_blocked')}
          {blocked && ` (${t('Block reason')}: ${blocked.reason})`}
        </div>
      </div>
      <Form className='needs-validation' onSubmit={formik.handleSubmit}>
        {!blocked && (
          <FormGroup className='mb-3'>
            <Label htmlFor='reason_id'>{t('Block reason')}</Label>
            <Input
              type='select'
              id='reason_id'
              name='reason_id'
              value={formik.values.reason_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={!!formik.errors.reason_id}
              disabled={reasonsIsLoading}
            >
              <option value={-1}>-</option>
              {reasons.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.reason}
                </option>
              ))}
            </Input>
            {formik.touched.reason_id && formik.errors.reason_id && (
              <FormFeedback type='invalid'>
                {formik.errors.reason_id}
              </FormFeedback>
            )}
            {!!reasonsError && <DangerAlert error={reasonsError} />}
          </FormGroup>
        )}
        <FormGroup className='mb-3'>
          <Label htmlFor='name'>{t('Comment')}</Label>
          <Input
            type='textarea'
            id='comment'
            name='comment'
            placeholder={t(
              blocked ? 'unblock_client_comment' : 'block_client_comment'
            )}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comment}
          />
        </FormGroup>
        {blockIsLoading || unblockIsLoading ? (
          <Loader />
        ) : (
          <Button
            color='primary'
            disabled={!reasons || blockIsError || unblockIsError}
          >
            {t(blocked ? 'Unblock client' : 'Block client')}
          </Button>
        )}
        {blockError && <DangerAlert error={blockError} />}
        {unblockError && <DangerAlert error={unblockError} />}
      </Form>
      {history ? (
        <>
          <h6 className='mt-3'>{t('Blocking history')}</h6>
          <List
            list={[...history].reverse()}
            fieldsDescription={fieldsDescription}
          />
        </>
      ) : (
        <small className='text-warning mx-2'>
          {t('Blocking history not found')}
        </small>
      )}
      {historyError && <DangerAlert error={historyError} />}
    </div>
  )
}

export default BlockClient
