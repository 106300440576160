import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import MetaBar from 'src/Components/Common/MetaBar'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'

import { getEntities } from 'src/store/actions'

import Client from 'src/client'
import model from 'src/model'

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  Table,
  Button
} from 'reactstrap'
import { useGetPromotionQuery } from 'src/services/promotions'
import { useGetSegmentCsvMutation } from 'src/services/calling'
import { useGetProductQuery } from '../../../services/products'

export const SegmentView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageReady, setPageReady] = useState(false)
  const [paramError, setParamError] = useState(null)
  const [collectorsByStage, setCollectorsByStage] = useState([])
  const [filterName, setFilterName] = useState([])
  const { entity, entityLoading, entityError } = useSelector((state) => ({
    entity: state.entities[Client.ENTITY_TYPE.SEGMENTS].entity || null,
    entityLoading: state.entities[Client.ENTITY_TYPE.SEGMENTS].entityLoading,
    entityError: state.entities[Client.ENTITY_TYPE.SEGMENTS].entityError
  }))

  const { data: promotion } = useGetPromotionQuery(entity?.promotion_id, {
    skip: entity?.promotion_id == null
  })
  const { data: product } = useGetProductQuery(entity?.product_id, {
    skip: !entity?.product_id
  })

  const [getSegmentCsv] = useGetSegmentCsvMutation()

  const promoName = promotion?.promo_name || ''
  const productCode = product?.product_code || ''

  const fieldsDescription = useMemo(
    () => ({
      name: { label: 'name', initial: '', placeholder: '' },
      min_dpd: { label: 'min_dpd', initial: '', placeholder: '' },
      max_dpd: { label: 'max_dpd', initial: '', placeholder: '' },
      min_score: { label: 'min_score', initial: '', placeholder: '' },
      max_score: { label: 'max_score', initial: '', placeholder: '' },
      tags: { fullWidth: true, isObj: true },
      promotion_id: { label: 'promotion_id' },
      product_code: { label: 'product_type' },
      is_auto: {
        label: 'Promotion activation',
        mappingValue: (is_auto) => {
          switch (is_auto) {
            case true:
              return t('Auto')
            case false:
              return t('Manual')
            default:
              return ''
          }
        }
      },
      request_type: {
        label: t('Loan_type'),
        mappingValue: (request_type) => {
          switch (request_type) {
            case 0:
              return t('All')
            case 1:
              return t('Primaries')
            case 2:
              return t('Secondaries')
            default:
              return ''
          }
        }
      },
      accrual_stop: {
        label: 'Stopping the accrual of interest and penalties',
        mappingValue: (auto_sale) => {
          switch (auto_sale) {
            case true:
              return t('yes')
            case false:
              return t('no')
            default:
              return t('Ignore')
          }
        }
      },
      auto_sale: {
        label: 'auto_sale',
        mappingValue: (auto_sale) => {
          switch (auto_sale) {
            case true:
              return t('yes')
            case false:
              return t('no')
            default:
              return ''
          }
        }
      },
      ip_count: { label: 'ip_count', initial: '', placeholder: '' },
      debt_load: { label: 'debt_load', initial: '', placeholder: '' },
      loans_per_user: {
        label: 'Max number of loans per employee',
        initial: '',
        placeholder: ''
      }
    }),
    [t]
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    if (id) {
      dispatch(getEntities(Client.ENTITY_TYPE.SEGMENTS, id))
      Client.getStageCollectors(id).then((res) => {
        setCollectorsByStage(
          res.map((collector) => ({
            ...collector,
            FIO: `${collector.surname} ${collector.name} ${collector.midname}`.toLowerCase()
          }))
        )
      })
    } else setParamError('No entity id found')
  }, [])

  useEffect(() => {
    setPageReady(!!entity)
  }, [entity])

  const handleDownload = async () => {
    try {
      const blob = await getSegmentCsv(entity.id).unwrap()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Выгрузка из сегмента.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (err) {
      console.error('Ошибка при загрузке файла:', err)
    }
  }

  const title = `${t('Segments')}: ${t('View')}`
  document.title = title
  const bread = <BreadCrumb title={title} />
  const exportCsv = (
    <Button
      className='btn-label'
      color='primary'
      onClick={handleDownload}
      style={{ backgroundColor: '#405189' }}
    >
      <i className='ri-file-line label-icon align-middle fs-16 me-2'></i>
      {t('Export to CSV')}
    </Button>
  )

  const metaBar = (
    <MetaBar
      backLink={'/segments'}
      editLink={entity ? `/segments-edit?id=${entity.id}` : null}
      entity={entity}
      buttons={[exportCsv]}
    />
  )

  if (paramError || entityError) {
    return (
      <ErrorPage
        backLink={'/segments'}
        title={title}
        error={[paramError, entityError]}
      />
    )
  }

  if (!pageReady || entityLoading) {
    return <LoadingPage backLink={'/segments'} title={title} />
  }

  console.log('entity =>', entity)
  return (
    <>
      <UiContent />
      <div className='page-content'>
        {bread}
        <Card>
          <CardBody>
            {metaBar}
            <Row className='mt-3'>
              {Object.keys(fieldsDescription).map((fieldName) => {
                return (
                  <Col
                    className={
                      fieldsDescription[fieldName].fullWidth
                        ? 'col-12 mt-3'
                        : 'mt-3 col-auto'
                    }
                    key={`${fieldName}`}
                  >
                    <Label htmlFor={fieldName}>
                      {t(fieldsDescription[fieldName].label || fieldName)}
                    </Label>
                    <Input
                      type='text'
                      className='form-control form-control-sm'
                      value={model.fieldValueToText(
                        fieldName,
                        { ...entity, product_code: productCode },
                        fieldsDescription
                      )}
                      disabled
                    />
                  </Col>
                )
              })}

              <Col className={'mt-3 col-auto'}>
                <Label htmlFor='promo_name'>{t('Promotion')}</Label>
                <Input
                  type='text'
                  className='form-control form-control-sm'
                  value={entity?.promotion_id === null ? '' : promoName}
                  disabled
                />
              </Col>
            </Row>
            {collectorsByStage.length > 0 && (
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <span className={`ms-0 fs-16`}>{t('Employees')}</span>
                <Input
                  onChange={(evv) =>
                    setFilterName(evv.target.value.toLowerCase())
                  }
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  placeholder={t('Enter name')}
                  className='form-control form-control-sm'
                />
                {collectorsByStage
                  .filter((col) => col.FIO.includes(filterName))
                  .map((collector) => (
                    <p key={collector.id}>
                      {collector.surname} {collector.name} {collector.midname}
                    </p>
                  ))}
              </div>
            )}
            <div className='mt-3'>
              <h5>{t('Filters')}</h5>
              {entity?.extra_conditions ? (
                <Table
                  hover
                  className='table-sm align-middle mb-0'
                  style={{ tableLayout: 'auto', width: 'auto' }}
                >
                  <thead>
                    <tr>
                      <th scope='col'>{t('Filter')}</th>
                      <th scope='col'>{t('Operator')}</th>
                      <th scope='col'>{t('Value')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entity?.extra_conditions.map((condition, index) => (
                      <tr key={index}>
                        <td className='ps-3'>
                          {t(`Segments_${condition?.label}`)}
                        </td>
                        <td className='ps-3'>{t(condition?.operator)}</td>
                        <td className='ps-3'>
                          {Array.isArray(condition.value)
                            ? Object.values(condition.valueLabel).join(', ')
                            : t(condition.valueLabel || condition?.value)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <span>Фильтры отсутствуют</span>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
